import * as amplitude from '@amplitude/analytics-browser'
import { BaseEvent } from '@amplitude/analytics-types'
import { useCallback } from 'react'

import { Partner } from '@/gql/graphql'

export const useAmplitude = () => {
  const identifyApp = useCallback((partnerApp: Partner) => {
    const identifyEvent = new amplitude.Identify()
    identifyEvent.set('partnerApp', partnerApp)
    amplitude.identify(identifyEvent)
  }, [])

  const logEvent = useCallback(
    (
      eventInput: string | BaseEvent,
      eventProperties?: Record<string, any> | undefined,
    ) => {
      amplitude.track(eventInput, eventProperties)
    },
    [],
  )

  return { logEvent, identifyApp }
}

export const logAmplitude = (
  eventInput: string | BaseEvent,
  eventProperties?: Record<string, any> | undefined,
) => amplitude.track(eventInput, eventProperties)
